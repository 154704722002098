import React, { useEffect, useState } from "react";
import { styled, createGlobalStyle } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const Back = styled.div`
  background-color: black;
  height: 100vh;
`;

const Nav = styled.div`
  position: sticky;
  background-color: black;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 4vh;
  }
`;

const Contents = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

const Profile = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: white;
  object-fit: cover;
  border: none;
`;

const SlideWrapper = styled.div`
  display: flex;
`;

const Box = styled(motion.div)`
  width: 300px;
  height: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  background-color: white;
`;

const SlideZone = styled.div`
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 100px;
`;

const Button = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
  filter: invert(1);
  &:hover {
    opacity: 0.8;
  }
`;

const ClientName = styled.span`
  color: white;
  font-size: 5vw;
`;

const PhotoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 800px;
`;

const PhotoBox = styled.div`
  width: 20%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s;
  }

  &:hover img {
    filter: brightness(70%);
  }
`;

const box = {
  entry: (isBack) => {
    return {
      x: isBack ? -100 : 100,
      opacity: 0,
      scale: 0,
      transition: { duration: 0.7 },
    };
  },
  center: { x: 0, opacity: 1, scale: 1, transition: { duration: 0.7 } },
  exit: (isBack) => {
    return {
      x: isBack ? 100 : -100,
      opacity: 0,
      scale: 0,
      transition: { duration: 0.7 },
    };
  },
};

function App() {
  const [clientId, setClientId] = useState(null);
  const [imageCount, setImageCount] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [visible, setVisible] = useState(1);
  const [back, setBack] = useState(false);
  const [clickedBox, setClickedBox] = useState(1);

  const nextPlease = () => {
    setBack(false);
    setVisible((prev) => (prev === 3 ? 3 : prev + 1));
  };
  const prevPlease = () => {
    setBack(true);
    setVisible((prev) => (prev === 1 ? 1 : prev - 1));
  };

  useEffect(() => {
    const ws = new WebSocket("wss://ws.thelifegalleryvideo.com");
    // const ws = new WebSocket("ws://54.83.200.150:8080");
    ws.onopen = () => console.log("서버에 연결됨");
    ws.onclose = () => console.log("서버와의 연결 종료");
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.action === "sync") {
        setClientId(message.currentSelection.clientId);
        setNumbers(message.ids);
        setImageCount(3);
      }
    };

    return () => {
      if (ws) ws.close();
    };
  }, []);

  // changing video
  const handleButtonClick = (number) => {
    setClickedBox(number);
    if (clientId !== null) {
      const ws = new WebSocket("wss://ws.thelifegalleryvideo.com");
      // const ws = new WebSocket("ws://54.83.200.150:8080");
      ws.onopen = () => {
        ws.send(
          JSON.stringify({ action: "update", clientId, videoId: number })
        );
      };
    }
  };

  const handleClick = (number) => {
    console.log(`Clicked on photo ${number}`);
  };
  return (
    <>
      <GlobalStyle />
      <Back>
        <Nav>
          <img src="/image/logo.png" />
        </Nav>
        <Contents>
          <Content>
            <Profile
              src={`https://thelifegalleryvideo.com/image/${clientId}/profile.jpg`}
            />
            <ClientName>{clientId}</ClientName>
            <PhotoGrid>
              {Array.from({ length: imageCount }, (_, i) => i + 1).map(
                (number) => (
                  <PhotoBox key={number} onClick={() => handleButtonClick(number)}>
                    <img
                      src={`https://thelifegalleryvideo.com/image/${clientId}/${clientId}-${number}.jpg`}
                      alt={`Photo ${number}`}
                    />
                  </PhotoBox>
                )
              )}
            </PhotoGrid>
          </Content>
        </Contents>
      </Back>
    </>
  );
}

export default App;
